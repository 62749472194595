<template>
    <ODropdown placement="bottom-end" >
        <template #default="{target, open}">
            <span :ref="target" @click="open" class="timeline-header-dropdown" style="background-color: white; border: 1px solid gray; border-radius: 3px; padding: 2px 6px;">
                <i class="bi bi-funnel"></i>
            </span>
        </template>
        <template #dropdown="scope">
            <div :ref="scope.container">
                <div class="card dropdown-menu shadow p-3" style="width: 480px;" :style="{ opacity: '1'}">
                    <div class="d-flex range-container mb-3 ms-3 mt-1" style="position: relative;" :class="{'d-none': isLoading}">
                        <div class="inner-range-container" style="width: 350px; height: 13px;">
                            <input id="fromSlider" type="range" step="1" v-model="fromValue" @input="controlFromSlider" :min="min" :max="max" @change="applyDateFilter">
                            <input id="toSlider" step="1" type="range" v-model="toValue" @input="controlToSlider" :min="min" :max="max" :style="{background: vSlideColor}" @change="applyDateFilter">
                            <div class="mt-3" style="text-align: center;">
                                <span style="left: -50px;" >{{ fromDateLabel }}</span> - <span :style="{left: 'calc(100% - 66px)'}">{{ toDateLabel }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </ODropdown>
</template>

<script setup lang="ts">
import { dataGridRefKey } from 'o365.modules.vue.injectionKeys.js';
import type DataGridControl from 'o365.controls.DataGrid.ts';
import { ref, computed, nextTick, watch, inject, Ref } from 'vue';

const dataGridRef = inject<Ref<DataGridControl>>(dataGridRefKey);
const dataSource = dataGridRef.value.dataGridControl.dataObject;

const sliderColor = '#C6C6C6';
const rangeColor = 'lightsteelblue';
const filterRangeColor = 'steelblue';


const earliestDate = ref(new Date(2023, 0, 1));
const latestDate = ref(new Date(2023, 11, 31));
const includeCompletedIsChecked = ref(false);

const isLoading = ref(true);
const min = 0;
const max = computed(() => {
  return dateDiffInDays(earliestDate.value, latestDate.value)
});

const range = computed(() => {
    return max.value - min;
});

const fromValue = ref(0);
const toValue = ref(max.value);

const fromDateLabel = computed(() => {
  let newDate = new Date(earliestDate.value.getTime() + (fromValue.value * 24 * 60 * 60 * 1000));
  return newDate.toDateString();
});

const toDateLabel = computed(() => {
  let newDate = new Date(earliestDate.value.getTime() + (toValue.value * 24 * 60 * 60 * 1000));
  return newDate.toDateString();
});

const filteredActivitiesFromDate = ref(new Date());
const filteredActivitiesToDate = ref(new Date());

const fromFilterValue = computed(() => {
  return (filteredActivitiesFromDate.value - earliestDate.value) / (24 * 60 * 60 * 1000) - min;
});

const toFilterValue = computed(() => {
  return (Math.min((filteredActivitiesToDate.value - earliestDate.value) / (24 * 60 * 60 * 1000), toValue.value)) - min;
});

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function setInitInterval(){
  earliestDate.value = new Date(Math.min(...dataSource.getData().filter(x => isValidDate(new Date(x.PlannedStart))).map(x => new Date(x.PlannedStart))));
  latestDate.value = new Date(Math.max(...dataSource.getData().filter(x => isValidDate(new Date(x.PlannedFinish))).map(x => new Date(x.PlannedFinish))));
  nextTick(() => toValue.value = max.value);
  dataSource.off("DataLoaded", setInitInterval);
  isLoading.value = false;
}
function setFilterInterval(){
  filteredActivitiesFromDate.value = new Date(Math.min(...dataSource.getData().filter(x => isValidDate(new Date(x.PlannedStart))).map(x => new Date(x.PlannedStart))));
  filteredActivitiesToDate.value = new Date(Math.max(...dataSource.getData().filter(x => isValidDate(new Date(x.PlannedFinish))).map(x => new Date(x.PlannedFinish))));
}


dataSource.on("DataLoaded", setInitInterval);
dataSource.on("DataLoaded", setFilterInterval);


function applyDateFilter(){
  let fromDate = fromValue.value == min.value ? earliestDate.value : new Date(earliestDate.value.getTime() + fromValue.value * 24 * 60 * 60 * 1000);
  let toDate = toValue.value == max.value ? latestDate.value : new Date(earliestDate.value.getTime() + toValue.value * 24 * 60 * 60 * 1000);
  
  if(includeCompletedIsChecked.value){
    dataSource.filterObject.getItem("PlannedStart").clear();
  }else{
    dataSource.filterObject.getItem("PlannedStart").operator = "datebetween";
    dataSource.filterObject.getItem("PlannedStart").selectedValue = [fromDate, toDate];
  }
  dataSource.filterObject.apply();
}

function dateDiffInDays(first, second) {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}    

const vSlideColor = computed(() => {
    const rangeDistance = max.value - min;
    const fromPosition = fromValue.value - min;
    const toPosition = toValue.value - min;

    return `linear-gradient(
        to right,
        ${sliderColor} 0%,
        ${sliderColor} ${(fromPosition)/(rangeDistance)*100}%,

        ${rangeColor} ${(fromPosition)/(rangeDistance)*100}%,
        ${rangeColor} ${(fromFilterValue.value)/(rangeDistance)*100}%,

        ${filterRangeColor} ${(fromFilterValue.value)/(rangeDistance)*100}%,
        ${filterRangeColor} ${(toFilterValue.value)/(rangeDistance)*100}%,

        ${rangeColor} ${(toFilterValue.value)/(rangeDistance)*100}%,
        ${rangeColor} ${(toPosition)/(rangeDistance)*100}%,

        ${sliderColor} ${(toPosition)/(rangeDistance)*100}%,
        ${sliderColor} 100%)`
});

function controlFromSlider(pEvent) {
  parseInt(pEvent.target.value) < parseInt(toValue.value) ? null : fromValue.value = toValue.value;
}

function controlToSlider(pEvent) {
  parseInt(pEvent.target.value) > parseInt(fromValue.value) ? null : toValue.value = fromValue.value; 
}

window.dataSource = dataSource;
function toggleIncludeCompleted(pEvent){
  let fromDate = new Date(earliestDate.value.getTime() + fromValue.value * 24 * 60 * 60 * 1000);
  let toDate = new Date(earliestDate.value.getTime() + toValue.value * 24 * 60 * 60 * 1000);
  if(includeCompletedIsChecked.value){
      dataSource.filterObject.getItem("PlannedStart").clear();
      dataSource.filterObject.apply();
  }else{
      dataSource.filterObject.getItem("PlannedStart").operator = "datebetween";
      dataSource.filterObject.getItem("PlannedStart").selectedValue = [fromDate, toDate];
      dataSource.filterObject.apply();
  }

  
}
</script>

<style>

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #C6C6C6;
  cursor: pointer;
}

input[type=range]::-webkit-slider-thumb:hover {
  background: #f7f7f7;
}

input[type=range]::-webkit-slider-thumb:active {
  box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
  -webkit-box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
}


/* Firefox */
input[type=range]::-moz-range-thumb {

  pointer-events: all;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

input[type=range]::-moz-range-thumb:hover {
  background: #f7f7f7;
}

input[type=range]::-moz-range-thumb:active {
  box-shadow: inset 0 0 1px #387bbe, 0 0 9px #387bbe;
  -webkit-box-shadow: inset 0 0 1px #387bbe, 0 0 9px #387bbe;
}


input[type="number"] {
  color: #8a8383;
  width: 50px;
  height: 30px;
  font-size: 20px;
  border: none;
}


input[type="range"] {
  -webkit-appearance: none; 
  appearance: none;
  height: 4px;
  width: 100%;
  position: absolute;
  background-color: #C6C6C6;
  pointer-events: none;
  border-radius:14px;
}

#fromSlider {
  height: 0;
  z-index: 1;
  margin-top: 2px;
}


.bubble{
  border: 1px solid #e2e2e2;
  background-color: #fff;
  border-radius: 3px;
  color: #777;
  font-size: .8em;
  line-height: 24px;
  text-align: center;
  margin-bottom: 12px;
  margin-left: 5px;
  max-height: 26px;
  overflow: hidden;
  padding-left: 3px;
  padding-right: 3px;
}


.pin-bubble{
  position: absolute;
  top: 12px;
  color: #777;
}
</style>
